import { YoutubeEmbed } from '@new-ventures/ui';
import { PortableText } from '@portabletext/react';
import getVideoId from 'get-video-id';
import { values } from 'lodash';
import Link from 'next/link';

import { Text } from '../text';

import { image, numberedList } from './index.css';

import type { structuredText } from '@lib/queries';
import type { PortableTextReactComponents } from '@portabletext/react';

import { Box } from '~/components/box';
import { Image } from '~/components/image';
import { vars } from '~/styles/theme.css';

const componentMap: (heading?: boolean) => Partial<PortableTextReactComponents> = (heading) => ({
  types: {
    youtube: ({ value }) => (
      <Box marginY="0.5" position="relative" className={image}>
        <YoutubeEmbed id={getVideoId(value.url).id ?? ''} />
      </Box>
    ),
    image: ({ value }) => {
      return (
        <Box marginY="0.5" position="relative" className={image}>
          <Image
            _type="responsiveImage"
            image={value}
            alt={value.alt}
            style={{
              margin: '0 auto',
              objectFit: 'cover',
              maxWidth: '100%',
              maxHeight: '55vh',
              height: 'auto',
              width: 'auto',
              borderRadius: vars.borderRadius['radius-3'],
            }}
          />
        </Box>
      );
    },
  },

  block: {
    normal: ({ children }) => (heading ? <span>{children}</span> : <Text variant="body">{children}</Text>),
    grey: ({ children }) => (
      <Text variant="body" color="grey">
        {children}
      </Text>
    ),
    h1: ({ children }) => (
      <Text marginY="4" variant="h1">
        {children}
      </Text>
    ),
    h2: ({ children }) => (
      <Text marginTop="2" variant="h2">
        {children}
      </Text>
    ),
    h3: ({ children }) => (
      <Text marginTop="1" variant="h3">
        {children}
      </Text>
    ),
  },

  hardBreak: () => <Box />,

  list: {
    bullet: ({ children }) => (
      <Box as="ul" paddingLeft="1" display="flex" flexDirection="column" gap="0.5">
        {children}
      </Box>
    ),
    number: ({ children }) => (
      <Box as="ol" display="flex" flexDirection="column" gap="0.5" listStyle="number" counterReset="item">
        {children}
      </Box>
    ),
  },

  listItem: {
    bullet: ({ children }) => (
      <Box display="list-item" paddingLeft="0.25" listStyle="disc" listStylePosition="outside" as="li">
        <Text variant="body">{children}</Text>
      </Box>
    ),

    number: ({ children }) => (
      <Box
        className={numberedList}
        marginLeft="1.5"
        paddingLeft="0.25"
        display="list-item"
        listStyle="number"
        listStylePosition="outside"
        as="li"
      >
        <Text variant="body">{children}</Text>
      </Box>
    ),
  },

  marks: {
    link: ({ children, value }) => {
      const rel = !value?.href?.startsWith('/') ? 'noreferrer noopener' : undefined;
      return (
        <Link href={value?.href ?? '#'} rel={rel}>
          {children}
        </Link>
      );
    },

    highlight: ({ children }) => (
      <Text variant="none" display="inline" color="highlight" fontFamily="serif">
        {children}
      </Text>
    ),
    highlightBlue: ({ children }) => (
      <Text variant="none" display="inline" color="highlightBlue" fontFamily="serif">
        {children}
      </Text>
    ),
  },
});

interface Props {
  value: structuredText;
  heading?: boolean;
}

export const StructuredText = ({ heading, value }: Props) => (
  <Box display="flex" flexDirection="column" gap="1">
    <PortableText value={values(value)} components={componentMap(heading)} />
  </Box>
);
