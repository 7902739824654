import {
  FETCH_HOME,
  FETCH_ARTICLES,
  FETCH_ARTICLE,
  FETCH_ABOUT,
  FETCH_FAQ,
  FETCH_CONSTANTS,
  FETCH_ARTICLES_PAGE,
  FETCH_PAGE,
} from '@lib/queries';
import { client } from '@lib/sanity';

import type { Home, About, FAQ, Constants, Article, ArticlesPage, Page } from '@lib/queries';

export const getHomePage = async (): Promise<Home> => await client.fetch(FETCH_HOME);
export const getAboutPage = async (): Promise<About> => await client.fetch(FETCH_ABOUT);
export const getFAQPage = async (): Promise<FAQ> => await client.fetch(FETCH_FAQ);
export const getArticles = async (): Promise<Article[]> => await client.fetch(FETCH_ARTICLES);
export const getArticlesPage = async (): Promise<ArticlesPage> => await client.fetch(FETCH_ARTICLES_PAGE);
export const getArticle = async (slug: string): Promise<Article> => await client.fetch(FETCH_ARTICLE, { slug });
export const getPage = async (slug: string): Promise<Page> => await client.fetch(FETCH_PAGE, { slug });
export const getConstants = async (): Promise<Constants> => await client.fetch(FETCH_CONSTANTS);
