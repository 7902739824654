import { client } from '@lib/sanity';
import NextImage from 'next/image';
import { useNextSanityImage } from 'next-sanity-image';

import type { Image as ImageProps } from '@lib/queries';

type Props = ImageProps & {
  fill?: boolean;
  style?: object;
};

export const Image = ({ image, alt, style = {}, ...rest }: Props) => {
  const sanityImage = useNextSanityImage(client, image);
  const { src, height, width } = sanityImage ?? {};
  const props = rest.fill ? { ...rest, src } : { ...rest, src, height, width };
  const appliedStyles = rest.fill ? { objectFit: 'cover', ...style } : style;
  return <NextImage style={appliedStyles} {...props} alt={alt} />;
};
