import 'zod';
import { q } from 'groqd';

import {
  productBlockSchema,
  headingBlockSchema,
  heroSchema,
  whatsYourRhythmSchema,
  ctaBlockSchema,
  textBlockSchema,
  expertSchema,
  accordionSchema,
  imageSchema,
  structuredTextSchema,
  categorySchema,
  slugSchema,
  author,
} from './atoms';

import type { InferType } from 'groqd';

export const { query: FETCH_HOME, schema: homeSchema } = q('*')
  .filter("_type == 'home'")
  .grab({
    _type: q.literal('home'),
    title: q.string(),
    hero: heroSchema,
    highlight: headingBlockSchema,
    productBlock: productBlockSchema,
    whatsYourRhythm: whatsYourRhythmSchema,
    cta: ctaBlockSchema,
  })
  .slice(0);

export type Home = InferType<typeof homeSchema>;

export const { query: FETCH_ABOUT, schema: aboutSchema } = q('*')
  .filter("_type == 'about'")
  .grab({
    _type: q.literal('about'),
    body: textBlockSchema,
    experts: q.array(expertSchema),
    cta: ctaBlockSchema,
  })
  .slice(0);

export type About = InferType<typeof aboutSchema>;

export const { query: FETCH_FAQ, schema: faqSchema } = q('*')
  .filter("_type == 'faq'")
  .grab({
    _type: q.literal('faq'),
    title: q.string(),
    faq: accordionSchema,
    ctaBlock: ctaBlockSchema,
  })
  .slice(0);

export type FAQ = InferType<typeof faqSchema>;

const articleGrab = {
  _type: q.literal('article'),
  title: q.string(),
  slug: slugSchema,
  author: q('author').deref().grab(author),
  featuredImage: imageSchema,
  snippet: q.string(),
  body: structuredTextSchema,
  category: categorySchema,
  datePublished: q.string(),
};

export const { query: FETCH_ARTICLES, schema: articleSchema } = q('*')
  .filter("_type == 'article'")
  .order('datePublished desc')
  .grab(articleGrab);

export type Article = InferType<typeof articleSchema>[number];

export const { query: FETCH_ARTICLE } = q('*')
  .filter("_type == 'article'")
  .filter('slug.current == $slug')
  .grab(articleGrab)
  .slice(0);

export const { query: FETCH_ARTICLES_PAGE, schema: articlesPageSchema } = q('*')
  .filter("_type == 'articles'")
  .grab({
    _type: q.literal('articles'),
    title: q.string(),
    body: textBlockSchema,
    cta: ctaBlockSchema,
  })
  .slice(0);

export type ArticlesPage = InferType<typeof articlesPageSchema>;

export const { query: FETCH_CONSTANTS, schema: constantsSchema } = q('*')
  .filter("_type == 'constants'")
  .grab({
    _type: q.literal('constants'),
    appUrl: q.string(),
    email: q.string(),
    downloadDescription: structuredTextSchema.optional(),
  })
  .slice(0);

export type Constants = InferType<typeof constantsSchema>;

export const { query: FETCH_PAGE, schema: pageSchema } = q('*')
  .filter("_type == 'page'")
  .filter('slug.current == $slug')
  .grab({
    _type: q.literal('page'),
    slug: q.slug('slug'),
    content: textBlockSchema,
  })
  .slice(0);

export type Page = InferType<typeof pageSchema>;
