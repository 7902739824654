import { q } from 'groqd';

import type { InferType } from 'groqd';

export const slugSchema = q.object({
  _type: q.literal('slug'),
  current: q.string(),
});

export type Slug = InferType<typeof slugSchema>;

export const sanityImage = q.object({
  _type: q.literal('image'),
  asset: q.object({
    _ref: q.string(),
  }),
});

export const structuredTextSchema = q.array(
  q.object({
    _type: q.literal('block'),
    style: q.string(),
    markDefs: q
      .array(
        q.object({
          _type: q.string(),
          _key: q.string(),
          href: q.string(),
        })
      )
      .optional(),
    asset: q
      .object({
        _ref: q.string(),
      })
      .optional(),

    children: q.array(
      q.object({
        _type: q.string(),
        text: q.string(),
      })
    ),
  })
);

export type structuredText = InferType<typeof structuredTextSchema>;

export const headingSchema = structuredTextSchema;
export type heading = structuredText;

export const ctaSchema = q.object({
  _type: q.literal('cta'),
  text: q.string(),
  link: q.string(),
});

export const imageSchema = q.object({
  _type: q.literal('responsiveImage'),
  image: sanityImage,
  alt: q.string(),
});

export type Image = InferType<typeof imageSchema>;

export const textBlockSchema = q.object({
  _type: q.literal('textBlock'),
  title: headingSchema,
  body: structuredTextSchema,
  cta: ctaSchema,
});

export type TextBlock = InferType<typeof textBlockSchema>;

export const heroSchema = q.object({
  _type: q.literal('hero'),
  title: headingSchema,
  body: q.string(),
  cta: ctaSchema,
  image: imageSchema,
});

export type HeroSchema = InferType<typeof heroSchema>;

export const productBlockSchema = q
  .array(
    q.object({
      _key: q.string(),
      _type: q.literal('productBlock'),
      title: structuredTextSchema,
      body: structuredTextSchema,
      icon: imageSchema,
    })
  )
  .max(3);

export type ProductBlockProps = InferType<typeof productBlockSchema>;

const stageSchema = q.object({
  preHeading: q.string(),
  heading: q.string(),
  body: structuredTextSchema,
});
export const whatsYourRhythmSchema = q.object({
  _type: q.literal('whatsYourRhythm'),
  title: headingSchema,
  body: structuredTextSchema,
  stage1: stageSchema,
  stage2: stageSchema,
  stage3: stageSchema,
  stage4: stageSchema,
});

export type WhatsYourRhythmProps = InferType<typeof whatsYourRhythmSchema>;

export const headingBlockSchema = q.object({
  _type: q.literal('headingBlock'),
  text: structuredTextSchema,
});

export type HeadingBlock = InferType<typeof headingBlockSchema>;

export const ctaBlockSchema = q.object({
  _type: q.literal('ctaBlock'),
  title: structuredTextSchema,
  body: structuredTextSchema,
  cta: ctaSchema,
  image: imageSchema,
});

export type CtaBlockProps = InferType<typeof ctaBlockSchema>;

export const expertSchema = q.object({
  _type: q.literal('expert'),
  name: q.string(),
  title: q.string(),
  image: imageSchema,
  quote: structuredTextSchema,
});

export type Expert = InferType<typeof expertSchema>;

export const accordionSchema = q.object({
  _type: q.literal('accordion'),
  title: headingSchema,
  description: structuredTextSchema,
  items: q.array(
    q.object({
      _key: q.string(),
      _type: q.literal('accordion item'),
      title: q.string(),
      body: structuredTextSchema,
    })
  ),
});

export type AccordionProps = InferType<typeof accordionSchema>;

export const author = {
  _type: q.literal('author'),
  name: q.string(),
  title: q.string(),
  avatar: imageSchema,
};
export const authorSchema = q.object(author);

export type Author = InferType<typeof authorSchema>;

export const categorySchema = q.object({
  _type: q.literal('category'),
  title: q.string(),
});
