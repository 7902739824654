import { createClient } from 'next-sanity';

import type { SanityClient } from 'next-sanity';

export const client: SanityClient = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  useCdn: process.env.NEXT_PUBLIC_SANITY_DATASET === 'production',
  apiVersion: '2023-05-03',
});
